// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let costCheck = await store
         .getState()
         .blockchain.smartContract.methods.costCheck()
         .call();
      let maxPerWL = await store
         .getState()
         .blockchain.smartContract.methods.maxPerWL()
         .call();
      let freePerWL = await store
         .getState()
         .blockchain.smartContract.methods.freePerWL()
         .call();
      let freeMints = await store
        .getState()
        .blockchain.smartContract.methods.freeMints(await store.getState().blockchain.account)
        .call();
      let leaf = await store
        .getState()
        .blockchain.smartContract.methods._leaf(await store.getState().blockchain.account)
        .call();  
      let WLClaims = await store
        .getState()
        .blockchain.smartContract.methods.WLClaims(await store.getState().blockchain.account)
        .call();
    

      dispatch(
        fetchDataSuccess({
          totalSupply,
          costCheck,
          freeMints,
          leaf,
          freePerWL,
          maxPerWL,
          WLClaims,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
