import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled, { keyframes } from 'styled-components';
const Web3 = require('web3');
const ethereumRpcUrl = 'https://eth.llamarpc.com';

const web3 = new Web3(ethereumRpcUrl);
//import { ethers } from 'ethers';

const slideInAnimation = keyframes`
from {
  opacity: 0;
  transform: translateY(-300px);
  text-shadow: none; /* Start without shadow */
}
to {
  opacity: 1;
  transform: translateY(0);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Apply shadow on animation end */
}
`;

const slideDownAnimation = keyframes`
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const dotAnimation = keyframes`
  0%, 100% {
    content: " .";
  }
  33% {
    content: " ..";
  }
  66% {
    content: " ...";
  }
`;


const ButtonText = styled.span`
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: dark;
  animation: ${slideDownAnimation} 1s ease-in-out forwards; /* Animation for title */
  opacity: 0;
  text-decoration: none;
`;

const { MerkleTree } = require('merkletreejs')
const keccak256 = require('keccak256')

const whiteListAddresses = require('./addresses.json')


const leafNodes = whiteListAddresses.map(x => keccak256(x))
const merkleTree = new MerkleTree(leafNodes ,keccak256, {sortPairs: true});
const rootHash = merkleTree.getRoot()

//const rootHash = whitelistMerkletree.getRoot();


//const whiteListRootHash = whitelistMerkletree.getHexRoot()

//const hashedAddress = keccak256(whiteListAddresses[0])
//const proof = whitelistMerkletree.getHexProof(hashedAddress)

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 12px 24px; /* Adjust padding for height and width */
  border-radius: 10px; /* Reduced border radius for a more rectangular look */
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  color: dark;
  width: 150px; /* Increase the width for a bigger button */
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  transition: box-shadow 0.3s ease-in-out; /* Added transition for smoother shadow change */
  
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const VerifydButton = styled.button`
padding: 15px 25px;
border-radius: 10px;
border: none;
background-color: var(--verifybutton);
font-weight: bold;
color: dark;
width: 220px;
cursor: pointer;
position: relative;
overflow: hidden;
transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
animation: ${slideDownAnimation} 0.5s ease-in-out 1s forwards;
opacity: 0;

::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--verifybutton-hover);
  transition: top 0.3s;
  z-index: -1;
}

:hover {
  color: var(--secondary-text-hover);
  transform: translate3d(0, -2px, 0); /* Using translate3d for hover effect */
}

:hover::before {
  top: 0;
}

:active {
  transform: translateY(0);
}
`;


export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: dark;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const TextAnim = styled.p`
font-size: 32px;
text-align: center;
color: var(--accent-text);
animation: ${slideInAnimation} 1s ease-in-out forwards;
opacity: 0;
-webkit-text-stroke: 1px rgba(0, 0, 0, 0.5); /* Dark outline */
color: transparent; /* Make the text transparent to show only the outline */
`;

export const TextTitle =styled.h1`
  font-size: 50px;
  text-align: center;
  font-weight: bold;
  color: transparent;
  /* -webkit-text-stroke: 2px rgba(0, 0, 0, 0.5); /* Dark outline */*/
  font-family: 'YourChosenFont', sans-serif; /* Replace with your preferred font */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Shadow for effect */
  animation: ${slideDownAnimation} 1s ease-in-out forwards; /* Animation for title */
  opacity: 0;
`;

export const TextMint = styled.h1`
  font-size: 50px;
  text-align: center;
  font-weight: bold;
  color: #7FFF7F;/* Set the font color to green */
  /* -webkit-text-stroke: 2px rgba(0, 0, 0, 0.5); /* Dark outline */*/
  font-family: 'Roboto', sans-serif; /* Replace 'Roboto' with your preferred font */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Shadow for effect */
  animation: ${slideDownAnimation} 1s ease-in-out forwards; /* Animation for title */
  opacity: 0;
`;


export const headerStyle = {
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '4px', // Further reduce padding for a thinner header
  backgroundColor: 'rgba(255, 255, 255, 0.5)', // Set a more transparent background color
  backdropFilter: 'blur(8px)', // Apply a slight blur effect for transparency (for modern browsers)
  zIndex: 1000, // Set a high z-index to ensure the header is on top
};

export const logoStyle = {
    width: '40px',
    height: 'auto',
    cursor: 'pointer',
    margin: '0 6px', // Add margin for both sides of the logos
  };

  export const twitterLogoStyle = {
    ...logoStyle,
    order: 2, // Adjust order to position Twitter logo on the right
  };

  export const otherLogoStyle = {
    ...logoStyle,
    order: -1, // Adjust order to position other logo at the start (left)
    marginRight: 'auto', // Pushes the logo to the far left
  };


export const TextTitle2 =styled.h1`
  font-size: 50px;
  text-align: center;
  font-weight: bold;
  color: dark;
  /* -webkit-text-stroke: 2px rgba(0, 0, 0, 0.5); /* Dark outline */*/
  font-family: 'YourChosenFont', sans-serif; /* Replace with your preferred font */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Shadow for effect */
  animation: ${slideDownAnimation} 1s ease-in-out forwards; /* Animation for title */
  opacity: 0;
`;


function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [showResult, setShowResult] = useState(true);
  const [finalResult, setFinalResult] = useState(true);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const leaf = keccak256(blockchain.account) // address from wallet using walletconnect/metamask
  const proof = merkleTree.getHexProof(leaf)

  // VERIFY WL WALLETS 

  const claimingAddress = data.leaf
  
  const hexProof = merkleTree.getHexProof(claimingAddress);


  function checkWL() 
  {
    if((data.maxPerWL - data.WLClaims) == 0)
      return false;
    else return merkleTree.verify(hexProof, claimingAddress, rootHash);
  }

  function checkWLAvailable() 
  {
    if((data.maxPerWL - data.WLClaims) == 0)
      return false;
    else return true;
  }

  const checkNoWLAvailable = () => 
  {
    if((data.maxPerWL - data.WLClaims) == 0)
      return true;
    else return false;
  }

 /* const claimNFTs = () => {
    //let costInspect = data.costInspect;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(getPrice());
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit * mintAmount);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .presaleMint(mintAmount, proof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };*/

  const claimNFTs = async () => {
    try {
        let totalCostWei = String(getPrice());
       // let totalGasLimit = String(gasLimit);

        console.log("Total Cost in Wei: ", totalCostWei);

        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);

        // Estimate gas for batch minting
        const estimatedGas = await blockchain.smartContract.methods
            .WlMint(mintAmount, proof)
            .estimateGas({
                from: blockchain.account,
                to: CONFIG.CONTRACT_ADDRESS,
                value: totalCostWei,
            });

        console.log("Estimated Gas: ", estimatedGas);

        // Adjust the estimated gas limit as needed (e.g., adding a buffer)
        let adjustedGasLimit = Math.ceil(estimatedGas * 1.15); // Adding 15% buffer

        console.log("Adjusted Gas Limit: ", adjustedGasLimit);

        // Get the current gas price from the network using web3.js
        const gasPrice = await web3.eth.getGasPrice();
        const userGasPrice = web3.utils.fromWei(gasPrice, 'gwei'); // Convert to Gwei

        const maxPriorityFeePerGas = web3.utils.toWei('2', 'gwei'); // Set max priority fee to 10 Gwei

        // Sending the transaction with the adjusted gas limit
        const receipt = await blockchain.smartContract.methods
            .WlMint(mintAmount,proof)
            .send({
                maxPriorityFeePerGas,
                maxFeePerGas: web3.utils.toWei(userGasPrice, 'gwei'), // Set max fee to the user gas price
                gasLimit: String(adjustedGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            });

        console.log("Transaction Receipt: ", receipt);
        setFeedback(
            `Amazing, the ${CONFIG.NFT_NAME} is yours! Go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
    } catch (error) {

      const errorMessageParts = error.message.split(':');
      const errorMessage = errorMessageParts[1] || "An error occurred.";
      //console.error("Error: ", error);
      //console.error("Error Details: ", error.message); // Log the specific error message
      setFeedback("Sorry, something went wrong. Please try again later. Error: " + errorMessage); // Provide the error message to the user
      setClaimingNft(false);
  }
};

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if ((data.maxPerWL - data.WLClaims) == 0)
    {
      newMintAmount = 0;
    }
    else if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > (data.maxPerWL - data.WLClaims)) {
      newMintAmount = data.maxPerWL - data.WLClaims;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };


  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);


  function getPrice()
  {
   
  if(data.freeMints < data.freePerWL)
  {
      if((data.freePerWL - data.freeMints) > mintAmount)
        return 0
      else   
        return (mintAmount * data.costInspect) - ((data.freePerWL - data.freeMints) * data.costInspect);  
  }
  else 
    return (mintAmount * data.costInspect);  
    
  }



    useEffect(() => {
      const delay = 4000;
  
      // Initially show positive bool
      const initialTimer = setTimeout(() => {
        setShowResult(true);
      }, delay / 2);
  
      // After 1 second, check the result using checkWLAvailable()
      const finalTimer = setTimeout(() => {
        const isWLAvailable = checkWLAvailable();
        setFinalResult(isWLAvailable);
        setShowResult(false); // Hide the initial positive bool message
      }, delay);

      return () => clearTimeout(timer); // Cleanup on unmount or dependency change
    }, []);
  
  
  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/background.png" : null}
      >        
       <div style={headerStyle}>
       <img
          src="/config/images/logo.png"
          alt="Logo"
          style={otherLogoStyle}
       />
       {/* Twitter logo */}
       <img
          src="/config/images/twitter-logo.png"
          alt="Twitter Logo"
          onClick={() => window.location.href = 'https://twitter.com/LarryPunks'}
          style={twitterLogoStyle}
       />
        {/* Website logo */}
       
    </div>
    
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              padding: 24,
              borderRadius: "50%", // To create a circular shape
              border: "1px solid var(--primary-text)", // Changed border property to 'solid'
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
              backgroundColor: "rgba(255, 255, 255, 0.2)", // Adjust the alpha value for transparency (0.1 for very transparent)
            }}
            image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
          >
              
          {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                   
                    <s.SpacerSmall />
                    <s.SpacerSmall />
                    <s.SpacerSmall />
                    <s.SpacerSmall />
                    <VerifydButton
                     onClick={(e) => {
                      e.preventDefault();        
                      dispatch(connect());  
                      getData();
                     }}
                    >
                    <>
                <TextTitle2
                style={{
                   textAlign: "center",
                   fontSize: 20,
                   fontWeight: "bold",
                   color: "vart--accent-text2",
                       }}
                    >
                  Check Wallet 
             </TextTitle2>
               </>
          </VerifydButton> 
                       {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : ( 
                  <>
                   {checkWL() ? (
              <>
                 <TextMint
                 style={{
                 textAlign: "center",
                 fontSize: 50,
                 fontWeight: "bold",

                }}
             >
                   Whitelisted 
             </TextMint>

             <s.SpacerSmall />
             
             <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 38,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
             
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {mintAmount} {CONFIG.SYMBOL} for {getPrice()/1000000000000000000}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />
             
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (


                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
               </>
            )
            : !checkNoWLAvailable() ? ( 
              <s.TextTitle
              style={{
                 textAlign: "center",
                fontSize: 50,
                 fontWeight: "bold",
                 color: "#FF0040",
           }}
           >
                Not Whitelisted<br />
                <s.SpacerSmall />
                <s.SpacerSmall />
     <VerifydButton>
       <a href="https://larrypunks.com" style={{ color: "#000000", textDecoration: "none" }}>
       <ButtonText>Public Mint</ButtonText>  </a>
     </VerifydButton>
              </s.TextTitle>) : 
              
              showResult ? ( 
                
                <s.TextTitle
                style={{
                   textAlign: "center",
                  fontSize: 40,
                  fontWeight: "bold",
                   color: "var(--accent-text)",
             }}
             >
                  Wait ... 
                </s.TextTitle>) :
                           
            <s.TextTitle
               style={{
                  textAlign: "center",
                 fontSize: 30,
                 fontWeight: "bold",
                  color: "var(--accent-text)",
            }}
            >
                 You already minted your WL allocation!<br />
                 <s.SpacerSmall />
                 <s.SpacerSmall />
      <VerifydButton>
        <a href="https://larrypunks.com" style={{ color: "#000000", textDecoration: "none" }}>
        <ButtonText>Public Mint</ButtonText>  </a>
      </VerifydButton>
               </s.TextTitle> }
                   </>
                  )}

            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              
            </s.TextDescription>

            
            
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
         
     
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
          
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
         
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
