const initialState = {
  loading: false,
  totalSupply: 0,
  costCheck: 0,
  freeMints: 0,
  leaf:0,
  freePerWL:0,
  maxPerWL:0,
  WLClaims:0,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        costInspect: action.payload.costCheck,
        freeMints: action.payload.freeMints,
        freePerWL: action.payload.freePerWL,
        maxPerWL: action.payload.maxPerWL,
        WLClaims: action.payload.WLClaims,
        leaf: action.payload.leaf,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
